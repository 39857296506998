body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* height: 80vh;  % */
  /* width: 100vw; % */
}
html,
body {
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.carousel .slide {
  background: none !important;
  background-color: rgba(255, 2555, 255, 0.6) !important;
}
.preloader {
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}
