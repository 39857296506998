

      .wrapper {
        display: flex;
         margin: 1rem 0 0;
         width: 100%;
         justify-content: center;        
      }
      
      .social_icon {
        margin: 0; 
        width: 1.6rem;      
        max-width: 30px;
      }

      .social_icon:hover {
        opacity: 0.3;
      }

    @media (max-width: 850px)  
    { 

      .wrapper {
        display: flex;
        margin: 1rem 0 0;
        width: 100%;
        justify-content: center;
      }

      
      .social_icon {
        margin: 0 .5rem;   
        
      }
  

    }

    @media (max-width: 415px)  
    
    { 

      .wrapper {
         margin: 1rem 0 0;
         width: 100%;
         justify-content: center;
        
      }

      .social_icon {
         margin: 0 auto;
         padding: .2em;
         width: 1.6rem;
                  
      }

      


    }