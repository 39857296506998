@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");

.eventsLinks {
    text-decoration: none;
    color: #198580;
    text-decoration: underline;
  }

.eventsLinks:hover {    
    color: #00C6BF;
  }

.FeaturedWrapper {
    background-color: rgba(248, 245, 241, 0.8);
    border-radius: 0.1rem;
}

.FeaturedBody {
    display: flex;
    padding: 0 0 1.5rem 0;
    flex-direction: column;
    font-family: "Lato", Helvetica, sans-serif;
}

.title {
    margin: 3rem 0 2rem 0rem;
    padding: 0 1rem 0 1rem;
    color: #333333;
    font-style: normal;
    font-weight: 900;
    text-align: center;
    font-size: 2rem;    
}

.title2 {
    margin: 0rem 0 2rem 0rem;
    padding: 0 1rem 0 1rem;
    color: #333333;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 1.85rem;      
}

.spanFeatured {
    font-style: italic;
    font-weight: 700;
}

.eventEmphasis {
    text-decoration: underline;
}

.content {
    color: #4a4857;
    margin: 0.5rem 0rem 1rem 0rem;
    padding: 0 8rem 0 8rem;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center;
    font-size: 1.3rem;
    line-height: 2rem;
}

.seeEvents {
    color: #1C3158;
    margin: 2rem 0 2rem 0; 
    white-space: wrap;
    text-decoration: none;
    font-weight: 700;
    margin: 0.5rem 0rem 0rem 0rem;
    padding: 0rem 0 0.5rem 0;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center !important;
    font-size: 1.3rem;
    line-height: 2rem;    
}

.seeEvents:hover {
    color: #6D0000;    
}

.seeWorks {
    color: #1C3158;
    white-space: wrap;
    text-decoration: none;
    font-weight: 700;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 0 0.5rem 0;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center !important;
    font-size: 1.7rem;
    line-height: 2rem;    
}

.seeWorks:hover {
    color: #6D0000;    
}

.coverImg {
    border-radius: 0.25rem;
    width: 50%;
    animation-name: imageLoad;
    animation-duration: 2s;
}

.coverImg2 {
    margin-bottom: .5rem;
    border-radius: 0.25rem;
    width: 50%;
    animation-name: imageLoad;
    animation-duration: 2s;
}


@keyframes featuredTitle {
    0% {
        color: #333333;
        opacity: 0;
    }

    100% {
        color: #007488;
        opacity: 1;
    }
}

@keyframes featuredContent {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes imageLoad {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 950px) {
    .FeaturedWrapper {
        background-color: rgba(248, 245, 241, 0.8);
        border-radius: 0.1rem;
        width: 100%;
    }
    .coverImg {
        margin: 0 auto;
        text-align: center;
        width: 75%;
    }
    .title {
        width: 85%;
        padding: 2rem 0 1.5rem 0;
        margin: 0 auto;
        font-size: 1.4rem;
    }
    .title2 {
        width: 85%;
        padding: 0rem 0 1.5rem 0;
        margin: 0 auto;
        font-size: 1.25rem;    
    }

    .content {
        width: 85%;
        margin: 0 auto;
        padding: 0.5rem 0 1.5rem 0;       
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .seeWorks, .seeEvents {
        font-size: 1.15rem;
        margin: 0 auto;
        padding: 0 .5rem 0 .5rem;
        text-justify: inter-word;
    }
}

@media (max-width: 475px) {
    .title {        
        font-size: 1.2rem;
    }
    .title2 {        
        font-size: 1.1rem;
    }
    .content {     
        margin: 0 auto;        
        font-size: 1rem;        
    }
    .seeWorks, .seeEvents {  
        padding: 0 .5rem 0 .5rem;      
        font-size: 1rem;
    }
}