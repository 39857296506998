
.preloader {
  background-color: #ffffff;
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.wrapper {  
    width: 75%;
    max-width: 1000px;
    margin: auto;
    padding-top: 2rem;
  }
  .events__wrapper {
    padding-bottom: 2rem;
    background-color: #ffffff;
  }
  
  