@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

.html {
  background-color: #f8f5f1;
  font-family: "Lato", Helvetica, sans-serif;
}

.cardBody {
  display: flex;
  padding: 0rem 0.5rem 0rem 0.3rem;
}

.bookImg {
  display: flex;
  align-content: center;
  margin: 4rem 2rem 0rem 0rem;
  flex-grow: 1;
  border-radius: 0.02rem;
}

.blurbBody {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-bottom: 2rem;
  flex-grow: 2;
}

.title {
  margin: 4rem 0 0 0rem;
  padding: .5em 0 .5em 0;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: left;
  font-size: 1.6rem;
 
}

.content {
  color: #333333;
  margin: 0;
  padding-bottom: 1em;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  justify-content: left;
  text-align: justify;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: "Lato";
}
.popup__overlay {
  background: rgba(0, 0, 0, 0.5);
}

.swal2-title {
  margin: 0;
  padding: 0;
}


.button__wrapper {
  text-align: right;
}

.img:hover {
  opacity: 0.3;
}


@media (max-width: 850px) {
  .cardBody {
    flex-direction: column;
  }
  .bookImg {
    margin: 0 auto;
    text-align: center;
    width: 55%;
  }
  .title {
    margin: 0;
    padding: 2rem 0 0;
    text-align: center;
  }
  .content {
    padding: 2rem 0;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .button__wrapper {
    margin: 0 auto; 
    padding-bottom: 1.5em;
    text-align: center;
  }

}


@media (max-width: 450px) {


  .cardBody {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

  }

   .bookImg {
    margin: 0 auto;
    text-align: center;
    width: 85%;
   }

  .content {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .button__wrapper {
    margin: 0 auto; 
    text-align: center;
    width: 95%;
    padding-bottom: 2em;
  }
} 
