@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");


.alertText {
  color: white;
  font-weight: 800;
  font-family: "Lato";
  letter-spacing: 0.09em;
  white-space: wrap;
  font-size: 1.2rem;
  padding: 0;
  margin: auto;
}

.alertTitle {
  color: #333333;
  font-weight: 800;
  font-family: "Lato";
  font-size: larger;
  margin: 0;
}
