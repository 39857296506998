@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");

.BiocardWrapper {
  background-color: #ffffff;
  border-radius: 0.1rem;
}
.preloader {
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.BioBody {
  display: flex;
  font-family: "Lato", Helvetica, sans-serif;
}

.BioWrapperImage {
  float: right;
  width: 50%;
}

.BioCardImage {
  margin: 5.75rem 1rem 3rem 1rem;
  width: 75%;
  max-width: 400px;
  border-radius: 0.25rem;
  animation-name: imageLoad;
  animation-duration: 2s;
}

@keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 

.BioBlurb {
  float: left;
  width: 50%;
  margin: 0;
}

.title {
  margin: 3rem 0 0 4rem;
  padding: 0 0 0 0;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: left;
  font-size: 1.6rem;
  
}

.content {
  color: #333333;
  display: flex;
  margin: 0.5rem 0rem 3rem 4rem;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  /* text-align: justify !important; */
  text-align: left;
  font-size: 1.3rem;
  line-height: 2rem;
}

.hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  border: 0;
  height: 1px;
  background: #d0d0d0;
  margin: 0 2rem;
  color: gray; /*why doesn't this become gray?*/
}

.quoteBody {
  display: flex;
  float: none;
  width: 100%;
  background-color: #f8f5f1;
}

.quote {
  float: none;
  padding: 1rem 1rem 0 1rem;
  margin: 2rem 2rem 0rem 2rem;
  font-size: 1.46rem;
  text-align: center;
  font-weight: 400;
  font-family: "Lato", Helvetica, sans-serif;
  font-style: italic;
  color: #333333;
}

.quoted {
  float: none;
  padding: 0.5em 1em 1em;
  margin-top: 0;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 400;
  color: #333333;
}

@media (max-width: 950px) {
  .BiocardWrapper {
    background-color: #ffffff;
    border-radius: 0.1rem;
    width: 100%;
  }

  .BioBody {
    margin: 0 auto;
    display: flex;
    width: 95%;
    flex-direction: column-reverse;
    text-align: center;
  }

  .BioWrapperImage {
    margin: 0 auto;
    width: 80%;
    padding-top: 1em;
  }

  .BioCardImage {
    margin: 0 auto;
    text-align: center;
    padding: 1em 0 2em;
    width: 75%;
    border-radius: 0.25rem;
  }

  .BioBlurb {
    margin: 0 auto;
    width: 85%;
    padding: 0em 1em 1em;
  }

  .title {
    margin: 0 auto;
    color: #333333;
    font-style: normal;
    font-weight: 900;
    text-align: center !important;
    font-size: 1.4rem;
  }

  .content {
    color: #333333;
    width: 100%;
    margin: 0 auto;
    padding-top: 0.8em;
    padding-bottom: 1.5rem;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: justify !important;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border: 0;
    height: 1px;
    background: #d0d0d0;
    margin: 0 2rem;
    color: gray; /*why doesn't this become gray?*/
  }

  .quoteBody {
    display: flex;
    font-family: "Lato", Helvetica, sans-serif;
    float: none;
    width: 100%;
    background-color: #f8f5f1;
  }

  .quote {
    float: none;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 400;
    font-style: italic;
  }

  .quoted {
    float: none;
    padding-bottom: 2rem;
    margin-top: 0;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 400;
  }
}

@media (max-width: 450px) {
  .BiocardWrapper {
    background-color: #ffffff;
    border-radius: 0.1rem;
    width: 100%;
  }

  .BioBody {
    margin: 0 auto;
    display: flex;
    width: 95%;
    flex-direction: column-reverse;
    text-align: center;
  }

  .BioWrapperImage {
    margin: 0 auto;
    width: 80%;
    padding-top: 1em;
  }

  .BioCardImage {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    border-radius: 0.25rem;
  }

  .BioBlurb {
    margin: 0 auto;
    width: 85%;
  }

  .title {
    margin: 0 auto;
    color: #333333;
    font-style: normal;
    font-weight: 900;
    text-align: center !important;
    font-size: 1.25rem;
  }

  .content {
    color: #333333;
    width: 100%;
    margin: 0 auto;
    padding-top: 0.5em;
    white-space: wrap;
    letter-spacing: 0.0325em;
    text-justify: inter-word;
    text-align: justify !important;
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border: 0;
    height: 1px;
    background: #d0d0d0;
    margin: 0 2rem;
    color: gray; /*why doesn't this become gray?*/
  }

  .quote {
    float: none;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    font-style: italic;
  }

  .quoted {
    float: none;
    padding-bottom: 2rem;
    margin-top: 0;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
  }
}