@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

.html {
  background-color: #f8f5f1;
}

.html .cardBody {
 
  background-color: rgba(248, 245, 241, 0.8) !important;
}

.img {
   
  width: 35%;
  display: flex;
  max-width: 300px;
  margin: auto;
  padding: 2rem 2rem 4rem 2rem;
  flex-grow: 1;
  border-radius: .02rem;
  animation-name: imageLoad;
  animation-duration: 3s;
}

@keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 

.img:hover {
  opacity: 0.3;
}
