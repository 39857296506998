@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

* {
  outline: none;
}
  .wrapper {
    display: flex;
    width: 95%;
    padding-left: 1.5rem;
    position: relative;
  }  

  .bookImg {
    width: 40% !important;
    margin: auto 0;
    padding: 2rem 2rem 4rem 2rem;
    max-height: 45rem;
    animation-name: imageLoad;
    animation-duration: 3s;
}

@keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 

  .content__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    padding: 0em 0.5em 1em 2em;
  }

  .content__header {
    width: 100%;
    margin: 0;
    padding-top: 3rem;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 900;
    color: #333333;
    
  }

  .content__text {
    width: 100%;
    margin: 0;
    margin-bottom: 2em;
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: justify;
    color: #333333;
  }

  .content__btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: auto;
  }

  .popup__overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .alertTitle {
    color: #333333;
    font-weight: 800;
    font-family: "Lato";
    font-size: larger;
    margin: 0;
  }

  .swal2-title {
    margin: 0;
    padding: 0;
  }

  .alertText {
    color: white;
    font-weight: 800;
    font-family: "Lato";
    letter-spacing: 0.09em;
    white-space: wrap;
    font-size: 1.2rem;
    padding: 0;
    margin: auto;
  }

  .img:hover {
    opacity: 0.3;
  }

  .button {
    margin: 0;
  }


@media (max-width: 850px) {
  .wrapper {
    display: flex;
    width: 95%;
    flex-direction: column;
    justify-items: center;
    margin: 0 auto;
    padding: 2rem 1.5rem;
  }

  .bookImg {
    width: 60% !important;
    margin: 0 auto;
    padding: 1.5em;
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .content__header {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: 900;
  
  }

  .content__text {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 2em 2em;
    text-align: left;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: justify;
    
  }

  .content__btn {
    margin: 0 auto;
    padding: 2rem
  }

  .popup__overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .alertTitle {
    color: #333333;
    font-weight: 800;
    font-family: "Lato";
    font-size: larger;
    margin: 0;
  }

  .swal2-title {
    margin: 0;
    padding: 0;
  }

  .alertText {
    color: white;
    font-weight: 800;
    font-family: "Lato";
    letter-spacing: 0.09em;
    white-space: wrap;
    font-size: 1.2rem;
    padding: 0;
    margin: auto;
  }

  .img:hover {
    opacity: 0.3;
  }

  .button {
    margin: 0;
  }
}

@media (max-width: 450px) {
  .wrapper {
    display: flex;
    width: 95%;
    flex-direction: column;
    justify-items: center;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    text-align: justify;
  }

  .bookImg {
    width: 95% !important;
    margin: 0 auto;
    padding: 1.5em;
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .content__header {
    width: 100%;
    margin: 0 auto;
    font-size: 1.25rem;
    font-weight: 900;
    text-align: center;
    color: #333333;
   
  }

  .content__text {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 2rem 2em;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.8rem;
    color: #333333;
  }

  .content__btn {
    margin: 0 auto;
    width: 95%;
    align-items: flex-end !important;
  }

  .popup__overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .alertTitle {
    color: #333333;
    font-weight: 800;
    font-family: "Lato";
    font-size: larger;
    margin: 0;
  }

  .swal2-title {
    margin: 0;
    padding: 0;
  }

  .alertText {
    color: white;
    font-weight: 800;
    font-family: "Lato";
    letter-spacing: 0.09em;
    white-space: wrap;
    font-size: 1.2rem;
    padding: 0;
    margin: auto;
  }

  .img:hover {
    opacity: 0.3;
  }

  .button {
    margin: 0;
  }
}
