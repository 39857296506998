
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");



.App {
  margin: auto 0;
  width: 100%; 
  text-align: center;
  background: url("./data/img/image3.jpg");
  background-size: cover;  
  font-family: "Lato", Helvetica, sans-serif;
  animation-name: imageLoad;
  animation-duration: 2s;
}

@keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 


/*Default font-size is 16px */