@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");

.EventsWrapper {
  background-color: rgba(248, 245, 241, 0.8);
  border-radius: 0.1rem;
}

.EventsBody {
  display: flex;
  padding: 0 0 1rem 0;
  flex-direction: column;
  font-family: "Lato", Helvetica, sans-serif;
}

.eventsLinks {
  text-decoration: none;
  color: #198580;
  text-decoration: underline;
}

.eventsLinks:hover {
  color: #00c6bf;
}

.title {
  margin: 3rem 0 1rem 0rem;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
}

.heading {
  margin: 1rem 0 0rem 0rem;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  font-size: 1.6rem;
}

.date {
  margin: 1.5rem 0 1rem 0rem;
  color: #198580;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
}

.time {
  margin: 0rem 0 0.5rem 0rem;
  color: #198580;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
}

.evImgcaption {
  color: #4a4857;
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 60%;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  text-align: justify !important;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
}

.content {
  color: #4a4857;
  margin: 0.5rem 0rem 0.5rem 0rem;
  padding: 0 8rem 0 8rem;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  text-align: justify !important;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
}

.HostEventMsg {
  color: #4a4857;
  margin: 1rem 0rem 0.5rem 0rem;
  padding: 0 8rem 0 8rem;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  text-align: justify !important;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
}

.evImg {
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  max-width: 80%;
  border-radius: 0.25rem;
  animation-name: imageLoad;
  animation-duration: 2s;
}

.evImg1 {
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  max-width: 60%;
  border-radius: 0.25rem;
  animation-name: imageLoad;
  animation-duration: 2s;
}

@keyframes featuredTitle {
  0% {
    color: #333333;
    opacity: 0;
  }

  100% {
    color: #007488;
    opacity: 1;
  }
}

@keyframes featuredContent {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 950px) {
  .EventsWrapper {
    background-color: rgba(248, 245, 241, 0.8);
    border-radius: 0.1rem;
    width: 100%;
  }
  .evImg {
    margin: 0 auto;
    padding: 0.25rem 0 0.25rem 0;
    text-align: center;
    width: 70%;
    border-radius: 0.25rem;
  }

  .evImg1 {
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    max-width: 70%;
  }
  .title {
    width: 85%;
    padding: 2rem 0 1.5rem 0;
    margin: 0 auto;
    font-size: 1.8rem;
  }
  .heading {
    width: 85%;
    padding: 0.5rem 0 1rem 0;
    margin: 0 auto;
    font-size: 1.5rem;
  }
  .date,
  .time {
    width: 85%;
    padding: 0.5rem 0 0.25rem 0;
    margin: 0 auto;
    text-align: center !important;
    font-size: 1.3rem;
  }
  .evImgcaption {
    width: 70%;
    margin: 0 auto;
    font-size: 1rem;
  }

  .content {
    width: 85%;
    margin: 0 auto;
    padding: 0.5rem 0 1rem 0;
    font-size: 1.2rem;
  }
}

@media (max-width: 475px) {
  .title {
    margin: 0 auto;
    font-size: 1.6rem;
  }
  .heading {
    margin: 0 auto;
    font-weight: 900;
    font-size: 1.45rem;
  }
  .date,
  .time {
    width: 85%;
    padding: 0.5rem 0 0.25rem 0;
    margin: 0 auto;
    font-size: 1.3rem;
  }
  .content {
    width: 85%;
    margin: 0 auto;
    line-height: 2rem;
    font-size: 1.18rem;
  }
  .evImgcaption {
    width: 70%;
    margin: 0 auto;
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}
