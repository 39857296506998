.menu_item a {
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
  margin: auto;
  padding: 0;
}

.menu_item:hover a {
  color: #e5e5e5;
}

@media (max-width: 850px) {

  .menu_item a {
    font-family: "Lato", sans-serif;
    margin: auto;
    padding: 0;
  }

  .menu_item:hover a {
    color: #e5e5e5;
  }
}

@media (max-width: 415px) {
 

  .menu_item a {
    font-family: "Lato", sans-serif;
    margin: auto;
    padding: 0;
  }
  



}
