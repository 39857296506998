.menu {
  margin: 0rem 3.25rem 0 3.25rem;
  padding: -1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.menu li {
  padding-top: 0 !important;
  margin: 0 !important;
}

.menu li a {
  text-decoration: none;
  color: #7E9CC9;
  padding: 0 1rem 0 1rem !important;
  text-transform: uppercase;
  font-weight: bold;
  justify-content: center;
  font-size: 1.3rem;
}

@media (max-width: 850px) {
  .menu {
    display: none;
  }
}
