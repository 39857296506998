@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

/* .preloader {
  background-color: #ffffff;
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
} */

@media (max-width: 1100px) {
  .carousel .slide {
    max-height: 10rem;
  }

} 



