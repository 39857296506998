@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");


  
      .header {
        width: 100%;
        background-color: #1C3158;
        margin: auto 0;
        padding: 0;
        font-family: "Lato", Helvetica, sans-serif;
      }

          
       .title {
        margin: auto 0;
        padding: 2rem 0 0 0;
        background-color: #1C3158;
        text-align: center;
        color: #e5e5e5;
        font-weight: 700;
        font-size: 2.3rem;
                     
      }        
     
      .header__wrapper {
        width: 100%;
      }

     
      .socials {        
        margin: auto;
        text-align: left;
        max-width: .2rem;
      }
  

@media (max-width: 850px) {

  
    .header {
      width: 100%;
      background-color: #1C3158;
      margin: auto 0;
      padding: 0;
      font-family: "Lato", Helvetica, sans-serif;
    }

        
     .title {
      margin: auto 0;
      padding: 2rem 0 0 0;
      background-color: #1C3158;
      text-align: center;
      color: #e5e5e5;
      font-weight: 700;
      font-size: 2.2rem;
                   
    }        
   
    .socials { 
      display: flex;          
      max-width: .1rem;
     
    }

}



@media (max-width: 415px) {
          
      .header, .header_wrapper {
        width: 100%;
        background-color: #1C3158;
        margin: auto 0;
        padding: 0;
        font-family: "Lato", Helvetica, sans-serif;
      }


      .title {
        margin: auto 0;
        background-color: #1C3158;
        padding-top: 1.2rem;
        text-align: center;
        color: #e5e5e5;
        font-weight: 700;
        font-size: 1.5rem;
        
              
      }
      
      .header__wrapper {
        width: 100%;
      }

     
      .socials {        
        margin: auto;
        max-width: .2rem;
      }
  
}