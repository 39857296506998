@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
/* look at url, how I added 300 font*/

.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding-bottom: 0.1rem;
  margin: 0;
  list-style: none;
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1c3158;
}
.wrapper li {
  padding: 0 1.4rem;
}

.wrapper li a {
  text-decoration: none;
  color: #7e9cc9;
  visibility: visible !important;
}
.hamburger_menu {
  display: none;
}

@media (max-width: 850px) {
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0.1rem;
    margin: 0;
    list-style: none;
    background-color: #1c3158;
  }
  .wrapper li {
    padding: 0 1.4rem;
  }

  .wrapper li a {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: #7e9cc9;
  }

  .hamburger_menu {
    display: block;
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 2.8rem;
    cursor: pointer;
  }
}
