@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Redressed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Redressed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Redressed&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Redressed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* height: 80vh;  % */
  /* width: 100vw; % */
}
html,
body {
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.carousel .slide {
  background: none !important;
  background-color: rgba(255, 2555, 255, 0.6) !important;
}
.preloader {
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}


.App {
  margin: auto 0;
  width: 100%; 
  text-align: center;
  background: url(/static/media/image3.24b0074d.jpg);
  background-size: cover;  
  font-family: "Lato", Helvetica, sans-serif;
  -webkit-animation-name: imageLoad;
          animation-name: imageLoad;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

@-webkit-keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imageLoad {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 


/*Default font-size is 16px */
.header_header__24D3z {
        width: 100%;
        background-color: #1C3158;
        margin: auto 0;
        padding: 0;
        font-family: "Lato", Helvetica, sans-serif;
      }

          
       .header_title__2Cfdv {
        margin: auto 0;
        padding: 2rem 0 0 0;
        background-color: #1C3158;
        text-align: center;
        color: #e5e5e5;
        font-weight: 700;
        font-size: 2.3rem;
                     
      }        
     
      .header_header__wrapper__1krbm {
        width: 100%;
      }

     
      .header_socials__2nocA {        
        margin: auto;
        text-align: left;
        max-width: .2rem;
      }
  

@media (max-width: 850px) {

  
    .header_header__24D3z {
      width: 100%;
      background-color: #1C3158;
      margin: auto 0;
      padding: 0;
      font-family: "Lato", Helvetica, sans-serif;
    }

        
     .header_title__2Cfdv {
      margin: auto 0;
      padding: 2rem 0 0 0;
      background-color: #1C3158;
      text-align: center;
      color: #e5e5e5;
      font-weight: 700;
      font-size: 2.2rem;
                   
    }        
   
    .header_socials__2nocA { 
      display: flex;          
      max-width: .1rem;
     
    }

}



@media (max-width: 415px) {
          
      .header_header__24D3z, .header_header_wrapper__1CyD_ {
        width: 100%;
        background-color: #1C3158;
        margin: auto 0;
        padding: 0;
        font-family: "Lato", Helvetica, sans-serif;
      }


      .header_title__2Cfdv {
        margin: auto 0;
        background-color: #1C3158;
        padding-top: 1.2rem;
        text-align: center;
        color: #e5e5e5;
        font-weight: 700;
        font-size: 1.5rem;
        
              
      }
      
      .header_header__wrapper__1krbm {
        width: 100%;
      }

     
      .header_socials__2nocA {        
        margin: auto;
        max-width: .2rem;
      }
  
}
.menu-item_menu_item__37I3d a {
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
  margin: auto;
  padding: 0;
}

.menu-item_menu_item__37I3d:hover a {
  color: #e5e5e5;
}

@media (max-width: 850px) {

  .menu-item_menu_item__37I3d a {
    font-family: "Lato", sans-serif;
    margin: auto;
    padding: 0;
  }

  .menu-item_menu_item__37I3d:hover a {
    color: #e5e5e5;
  }
}

@media (max-width: 415px) {
 

  .menu-item_menu_item__37I3d a {
    font-family: "Lato", sans-serif;
    margin: auto;
    padding: 0;
  }
  



}

/* look at url, how I added 300 font*/

.menu_wrapper__1CP2P {
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding-bottom: 0.1rem;
  margin: 0;
  list-style: none;
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #1c3158;
}
.menu_wrapper__1CP2P li {
  padding: 0 1.4rem;
}

.menu_wrapper__1CP2P li a {
  text-decoration: none;
  color: #7e9cc9;
  visibility: visible !important;
}
.menu_hamburger_menu__19oE- {
  display: none;
}

@media (max-width: 850px) {
  .menu_wrapper__1CP2P {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0.1rem;
    margin: 0;
    list-style: none;
    background-color: #1c3158;
  }
  .menu_wrapper__1CP2P li {
    padding: 0 1.4rem;
  }

  .menu_wrapper__1CP2P li a {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: #7e9cc9;
  }

  .menu_hamburger_menu__19oE- {
    display: block;
    margin: 0 auto;
    padding: 1rem 0;
    max-width: 2.8rem;
    cursor: pointer;
  }
}



      .social_wrapper__gUh-X {
        display: flex;
         margin: 1rem 0 0;
         width: 100%;
         justify-content: center;        
      }
      
      .social_social_icon__3i_9e {
        margin: 0; 
        width: 1.6rem;      
        max-width: 30px;
      }

      .social_social_icon__3i_9e:hover {
        opacity: 0.3;
      }

    @media (max-width: 850px)  
    { 

      .social_wrapper__gUh-X {
        display: flex;
        margin: 1rem 0 0;
        width: 100%;
        justify-content: center;
      }

      
      .social_social_icon__3i_9e {
        margin: 0 .5rem;   
        
      }
  

    }

    @media (max-width: 415px)  
    
    { 

      .social_wrapper__gUh-X {
         margin: 1rem 0 0;
         width: 100%;
         justify-content: center;
        
      }

      .social_social_icon__3i_9e {
         margin: 0 auto;
         padding: .2em;
         width: 1.6rem;
                  
      }

      


    }
.footer_wrapper__2c4rS {
  padding: 2rem 0;
  background-color: #1C3158;
  list-style: none;

}

.footer_copyright__3TrO3 {
  color: #7E9CC9;
  margin-top: 0;
  font-family: 'Josefin Sans', sans-serif;
}
 


@media (max-width: 850px) {

.footer_copyright__3TrO3 {
  margin-top: 1em;
  
}

}

.footer-menu_menu__1VPnP {
  margin: 0rem 3.25rem 0 3.25rem;
  padding: -1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.footer-menu_menu__1VPnP li {
  padding-top: 0 !important;
  margin: 0 !important;
}

.footer-menu_menu__1VPnP li a {
  text-decoration: none;
  color: #7E9CC9;
  padding: 0 1rem 0 1rem !important;
  text-transform: uppercase;
  font-weight: bold;
  justify-content: center;
  font-size: 1.3rem;
}

@media (max-width: 850px) {
  .footer-menu_menu__1VPnP {
    display: none;
  }
}

/* .preloader {
  background-color: #ffffff;
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
} */

@media (max-width: 1100px) {
  .bookslider_carousel__1ZvMp .bookslider_slide__2NxZD {
    max-height: 10rem;
  }

} 




* {
  outline: none;
}
  .slider-item_wrapper__SVy7E {
    display: flex;
    width: 95%;
    padding-left: 1.5rem;
    position: relative;
  }  

  .slider-item_bookImg__10KAv {
    width: 40% !important;
    margin: auto 0;
    padding: 2rem 2rem 4rem 2rem;
    max-height: 45rem;
    -webkit-animation-name: slider-item_imageLoad__20TCg;
            animation-name: slider-item_imageLoad__20TCg;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
}

@-webkit-keyframes slider-item_imageLoad__20TCg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slider-item_imageLoad__20TCg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 

  .slider-item_content__wrapper__3Rp4- {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    padding: 0em 0.5em 1em 2em;
  }

  .slider-item_content__header__3sI7O {
    width: 100%;
    margin: 0;
    padding-top: 3rem;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 900;
    color: #333333;
    
  }

  .slider-item_content__text__1bOUS {
    width: 100%;
    margin: 0;
    margin-bottom: 2em;
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: justify;
    color: #333333;
  }

  .slider-item_content__btn__2S4Op {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: auto;
  }

  .slider-item_popup__overlay__2F6gZ {
    background: rgba(0, 0, 0, 0.5);
  }

  .slider-item_alertTitle__1snFm {
    color: #333333;
    font-weight: 800;
    font-family: "Lato";
    font-size: larger;
    margin: 0;
  }

  .slider-item_swal2-title__1fijG {
    margin: 0;
    padding: 0;
  }

  .slider-item_alertText__3Ct3W {
    color: white;
    font-weight: 800;
    font-family: "Lato";
    letter-spacing: 0.09em;
    white-space: wrap;
    font-size: 1.2rem;
    padding: 0;
    margin: auto;
  }

  .slider-item_img__3zmie:hover {
    opacity: 0.3;
  }

  .slider-item_button__1hR4l {
    margin: 0;
  }


@media (max-width: 850px) {
  .slider-item_wrapper__SVy7E {
    display: flex;
    width: 95%;
    flex-direction: column;
    justify-items: center;
    margin: 0 auto;
    padding: 2rem 1.5rem;
  }

  .slider-item_bookImg__10KAv {
    width: 60% !important;
    margin: 0 auto;
    padding: 1.5em;
  }

  .slider-item_content__wrapper__3Rp4- {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .slider-item_content__header__3sI7O {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: 900;
  
  }

  .slider-item_content__text__1bOUS {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 2em 2em;
    text-align: left;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: justify;
    
  }

  .slider-item_content__btn__2S4Op {
    margin: 0 auto;
    padding: 2rem
  }

  .slider-item_popup__overlay__2F6gZ {
    background: rgba(0, 0, 0, 0.5);
  }

  .slider-item_alertTitle__1snFm {
    color: #333333;
    font-weight: 800;
    font-family: "Lato";
    font-size: larger;
    margin: 0;
  }

  .slider-item_swal2-title__1fijG {
    margin: 0;
    padding: 0;
  }

  .slider-item_alertText__3Ct3W {
    color: white;
    font-weight: 800;
    font-family: "Lato";
    letter-spacing: 0.09em;
    white-space: wrap;
    font-size: 1.2rem;
    padding: 0;
    margin: auto;
  }

  .slider-item_img__3zmie:hover {
    opacity: 0.3;
  }

  .slider-item_button__1hR4l {
    margin: 0;
  }
}

@media (max-width: 450px) {
  .slider-item_wrapper__SVy7E {
    display: flex;
    width: 95%;
    flex-direction: column;
    justify-items: center;
    margin: 0 auto;
    padding: 2rem 1.5rem;
    text-align: justify;
  }

  .slider-item_bookImg__10KAv {
    width: 95% !important;
    margin: 0 auto;
    padding: 1.5em;
  }

  .slider-item_content__wrapper__3Rp4- {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .slider-item_content__header__3sI7O {
    width: 100%;
    margin: 0 auto;
    font-size: 1.25rem;
    font-weight: 900;
    text-align: center;
    color: #333333;
   
  }

  .slider-item_content__text__1bOUS {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 2rem 2em;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.8rem;
    color: #333333;
  }

  .slider-item_content__btn__2S4Op {
    margin: 0 auto;
    width: 95%;
    align-items: flex-end !important;
  }

  .slider-item_popup__overlay__2F6gZ {
    background: rgba(0, 0, 0, 0.5);
  }

  .slider-item_alertTitle__1snFm {
    color: #333333;
    font-weight: 800;
    font-family: "Lato";
    font-size: larger;
    margin: 0;
  }

  .slider-item_swal2-title__1fijG {
    margin: 0;
    padding: 0;
  }

  .slider-item_alertText__3Ct3W {
    color: white;
    font-weight: 800;
    font-family: "Lato";
    letter-spacing: 0.09em;
    white-space: wrap;
    font-size: 1.2rem;
    padding: 0;
    margin: auto;
  }

  .slider-item_img__3zmie:hover {
    opacity: 0.3;
  }

  .slider-item_button__1hR4l {
    margin: 0;
  }
}

.solid-button_buyButton__15zKo {
  background-color: #580c31;
  color: white;
  padding: 0.5rem 0.9rem;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  outline: none;
  border-style: none;
}

.solid-button_buyButton__15zKo:hover {
  background-color:#79013d;
}

@media (max-width: 450px) {
  .solid-button_buyButton__15zKo {
    width: 95%;
    font-size: .9rem;
  }
}

.storesListPopup_alertText__3X67v {
  color: white;
  font-weight: 800;
  font-family: "Lato";
  letter-spacing: 0.09em;
  white-space: wrap;
  font-size: 1.2rem;
  padding: 0;
  margin: auto;
}

.storesListPopup_alertTitle__1TkoH {
  color: #333333;
  font-weight: 800;
  font-family: "Lato";
  font-size: larger;
  margin: 0;
}



@media (min-width: 850px) {

.content_biowrapper__2kE1a {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;  
  padding: 5em 3em 1em 3em;
  border-radius: .25rem;

} 

.content_sliderwrapper__1UXcE {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;  
  padding: 1em 3em 5em 3em;
  border-radius: .25rem;

} 

}

@media (max-width: 850px) {

.content_sliderwrapper__1UXcE, .content_biowrapper__2kE1a {
  width: 70%;
  margin: 0 auto;
  padding: 2em;
  border-radius: .25rem;

}

}




.works-item_html__1WZHo {
  background-color: #f8f5f1;
  font-family: "Lato", Helvetica, sans-serif;
}

.works-item_cardBody__2dsYh {
  display: flex;
  padding: 0rem 0.5rem 0rem 0.3rem;
}

.works-item_bookImg__xxU1I {
  display: flex;
  align-content: center;
  margin: 4rem 2rem 0rem 0rem;
  flex-grow: 1;
  border-radius: 0.02rem;
}

.works-item_blurbBody__3B1s0 {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding-bottom: 2rem;
  flex-grow: 2;
}

.works-item_title__39R6k {
  margin: 4rem 0 0 0rem;
  padding: .5em 0 .5em 0;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: left;
  font-size: 1.6rem;
 
}

.works-item_content__hhErH {
  color: #333333;
  margin: 0;
  padding-bottom: 1em;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  justify-content: left;
  text-align: justify;
  font-size: 1.3rem;
  line-height: 2rem;
  font-family: "Lato";
}
.works-item_popup__overlay__1_L3g {
  background: rgba(0, 0, 0, 0.5);
}

.works-item_swal2-title__1jzuX {
  margin: 0;
  padding: 0;
}


.works-item_button__wrapper__sFUV7 {
  text-align: right;
}

.works-item_img__3tADj:hover {
  opacity: 0.3;
}


@media (max-width: 850px) {
  .works-item_cardBody__2dsYh {
    flex-direction: column;
  }
  .works-item_bookImg__xxU1I {
    margin: 0 auto;
    text-align: center;
    width: 55%;
  }
  .works-item_title__39R6k {
    margin: 0;
    padding: 2rem 0 0;
    text-align: center;
  }
  .works-item_content__hhErH {
    padding: 2rem 0;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .works-item_button__wrapper__sFUV7 {
    margin: 0 auto; 
    padding-bottom: 1.5em;
    text-align: center;
  }

}


@media (max-width: 450px) {


  .works-item_cardBody__2dsYh {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

  }

   .works-item_bookImg__xxU1I {
    margin: 0 auto;
    text-align: center;
    width: 85%;
   }

  .works-item_content__hhErH {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .works-item_button__wrapper__sFUV7 {
    margin: 0 auto; 
    text-align: center;
    width: 95%;
    padding-bottom: 2em;
  }
} 

.workspage_wrapper__2pRnr {
  width: 75%;
  max-width: 1000px;
  margin: auto;
  padding-top: 2rem;
}
.workspage_works__wrapper__3ni2w {
  padding-bottom: 2rem;
  background-color: #ffffff;
}
.workspage_preloader__2fOk- {
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.store-item_html__3f_KX {
  background-color: #f8f5f1;
}

.store-item_html__3f_KX .store-item_cardBody__3Nf-y {
 
  background-color: rgba(248, 245, 241, 0.8) !important;
}

.store-item_img__2fblx {
   
  width: 35%;
  display: flex;
  max-width: 300px;
  margin: auto;
  padding: 2rem 2rem 4rem 2rem;
  flex-grow: 1;
  border-radius: .02rem;
  -webkit-animation-name: store-item_imageLoad__1hILS;
          animation-name: store-item_imageLoad__1hILS;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

@-webkit-keyframes store-item_imageLoad__1hILS {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes store-item_imageLoad__1hILS {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 

.store-item_img__2fblx:hover {
  opacity: 0.3;
}

.buy-page_preloader__1bj0G {
  background-color: #ffffff;
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.buy-page_wrapper__2Bwk_ {
  height: 30%;
  width: 60%;
  max-width: 800px;
  margin: 2rem auto;
  padding-top: 2rem;
  background-color: rgba(248, 245, 241, 0.8) !important;
 
}
.buy-page_header__3B89D {
  font-size: 1.5rem;
  color: #333333;
  font-weight: 900;
  padding: 0 .5em;
  font-family: "Lato", Helvetica, sans-serif;
}

.buy-page_content__3NUKV {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 4em;
  font-family: "Lato", Helvetica, sans-serif;
}


@media (max-width: 850px) {

  .buy-page_header__3B89D, .buy-page_content__3NUKV {
    font-size: 1.5rem;
    font-weight: 900;
    font-family: "Lato", Helvetica, sans-serif;
  }

  }


  @media (max-width: 550px) {

    .buy-page_header__3B89D, .buy-page_content__3NUKV {
      font-size: 1.2rem;
      font-weight: 900;
      font-family: "Lato", Helvetica, sans-serif;

    }
  
    }

    
  @media (max-width: 450px) {

    .buy-page_header__3B89D {
      font-size: 1.2rem;
      font-weight: 900;
      font-family: "Lato", Helvetica, sans-serif;
    }
  
    }
.events-item_EventsWrapper__3jzyf {
  background-color: rgba(248, 245, 241, 0.8);
  border-radius: 0.1rem;
}

.events-item_EventsBody__3SMYz {
  display: flex;
  padding: 0 0 1rem 0;
  flex-direction: column;
  font-family: "Lato", Helvetica, sans-serif;
}

.events-item_eventsLinks__fbUBK {
  text-decoration: none;
  color: #198580;
  text-decoration: underline;
}

.events-item_eventsLinks__fbUBK:hover {
  color: #00c6bf;
}

.events-item_title__1lRWZ {
  margin: 3rem 0 1rem 0rem;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
}

.events-item_heading__1q6Qq {
  margin: 1rem 0 0rem 0rem;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  font-size: 1.6rem;
}

.events-item_date__vsVrN {
  margin: 1.5rem 0 1rem 0rem;
  color: #198580;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
}

.events-item_time__1Jg-5 {
  margin: 0rem 0 0.5rem 0rem;
  color: #198580;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
}

.events-item_evImgcaption__2BcRR {
  color: #4a4857;
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 60%;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  text-align: justify !important;
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
}

.events-item_content__2szOo {
  color: #4a4857;
  margin: 0.5rem 0rem 0.5rem 0rem;
  padding: 0 8rem 0 8rem;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  text-align: justify !important;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
}

.events-item_HostEventMsg__3oC12 {
  color: #4a4857;
  margin: 1rem 0rem 0.5rem 0rem;
  padding: 0 8rem 0 8rem;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  text-align: justify !important;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
}

.events-item_evImg__2Clfs {
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  max-width: 80%;
  border-radius: 0.25rem;
  -webkit-animation-name: events-item_imageLoad__3UCn0;
          animation-name: events-item_imageLoad__3UCn0;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.events-item_evImg1__3hlg7 {
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  max-width: 60%;
  border-radius: 0.25rem;
  -webkit-animation-name: events-item_imageLoad__3UCn0;
          animation-name: events-item_imageLoad__3UCn0;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

@-webkit-keyframes events-item_featuredTitle__5PL7U {
  0% {
    color: #333333;
    opacity: 0;
  }

  100% {
    color: #007488;
    opacity: 1;
  }
}

@keyframes events-item_featuredTitle__5PL7U {
  0% {
    color: #333333;
    opacity: 0;
  }

  100% {
    color: #007488;
    opacity: 1;
  }
}

@-webkit-keyframes events-item_featuredContent__2lhLz {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes events-item_featuredContent__2lhLz {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes events-item_imageLoad__3UCn0 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes events-item_imageLoad__3UCn0 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 950px) {
  .events-item_EventsWrapper__3jzyf {
    background-color: rgba(248, 245, 241, 0.8);
    border-radius: 0.1rem;
    width: 100%;
  }
  .events-item_evImg__2Clfs {
    margin: 0 auto;
    padding: 0.25rem 0 0.25rem 0;
    text-align: center;
    width: 70%;
    border-radius: 0.25rem;
  }

  .events-item_evImg1__3hlg7 {
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    max-width: 70%;
  }
  .events-item_title__1lRWZ {
    width: 85%;
    padding: 2rem 0 1.5rem 0;
    margin: 0 auto;
    font-size: 1.8rem;
  }
  .events-item_heading__1q6Qq {
    width: 85%;
    padding: 0.5rem 0 1rem 0;
    margin: 0 auto;
    font-size: 1.5rem;
  }
  .events-item_date__vsVrN,
  .events-item_time__1Jg-5 {
    width: 85%;
    padding: 0.5rem 0 0.25rem 0;
    margin: 0 auto;
    text-align: center !important;
    font-size: 1.3rem;
  }
  .events-item_evImgcaption__2BcRR {
    width: 70%;
    margin: 0 auto;
    font-size: 1rem;
  }

  .events-item_content__2szOo {
    width: 85%;
    margin: 0 auto;
    padding: 0.5rem 0 1rem 0;
    font-size: 1.2rem;
  }
}

@media (max-width: 475px) {
  .events-item_title__1lRWZ {
    margin: 0 auto;
    font-size: 1.6rem;
  }
  .events-item_heading__1q6Qq {
    margin: 0 auto;
    font-weight: 900;
    font-size: 1.45rem;
  }
  .events-item_date__vsVrN,
  .events-item_time__1Jg-5 {
    width: 85%;
    padding: 0.5rem 0 0.25rem 0;
    margin: 0 auto;
    font-size: 1.3rem;
  }
  .events-item_content__2szOo {
    width: 85%;
    margin: 0 auto;
    line-height: 2rem;
    font-size: 1.18rem;
  }
  .events-item_evImgcaption__2BcRR {
    width: 70%;
    margin: 0 auto;
    font-size: 0.8rem;
    line-height: 1.3rem;
  }
}


.eventspage_preloader__1832i {
  background-color: #ffffff;
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.eventspage_wrapper__1DDqN {  
    width: 75%;
    max-width: 1000px;
    margin: auto;
    padding-top: 2rem;
  }
  .eventspage_events__wrapper__3A4wP {
    padding-bottom: 2rem;
    background-color: #ffffff;
  }
  
  
.contact-form_wrapper__j5AMe {
  width: 65%;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 5rem;
  padding: 1rem 0 1rem 0;
  max-width: 960px;
  background-color: rgba(248, 245, 241, 0.8);
 
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  border: none;
}

.contact-form_header__63lQs {
  padding: 1em 0 1em 0;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: #333333;
  
}

.contact-form_formRow__3PEV- {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
}

.contact-form_input__3Yfec {
  width: 65%;
  max-width: 60rem;
  height: 2rem;
  padding: 0.25rem 0.5rem 0.5rem .5rem;
  border: 1rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: none;
  font-family: "Lato", sans-serif;
  font-size: 1.15rem;
  
}

.contact-form_textarea__1JlaC {
  width: 65%;
  max-width: 60rem;
  height: 10rem;
  padding: 0.5rem;
  border: 1rem;
  border-radius: 0.25rem;
  border-style: none;
  font-size: 1.15rem;
  
}

.contact-form_formMessage__3U9iG {
  padding-bottom: 1em;
}
.contact-form_button__jLS0s {
  margin: 0 auto;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Lato", sans-serif;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Lato", sans-serif;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Lato", sans-serif;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Lato", sans-serif;
}


 @media (max-width: 450px) {

  .contact-form_header__63lQs {
    padding: 1em .5em 1em .5em;
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    
  }

.contact-form_button__jLS0s {
  margin: 0 auto;
  font-size: .5rem !important;
  width: 73% !important;
}

}
.biocard_BiocardWrapper__1igxS {
  background-color: #ffffff;
  border-radius: 0.1rem;
}
.biocard_preloader__1l8g7 {
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.biocard_BioBody__1L2DA {
  display: flex;
  font-family: "Lato", Helvetica, sans-serif;
}

.biocard_BioWrapperImage__3asFQ {
  float: right;
  width: 50%;
}

.biocard_BioCardImage__1Lr8g {
  margin: 5.75rem 1rem 3rem 1rem;
  width: 75%;
  max-width: 400px;
  border-radius: 0.25rem;
  -webkit-animation-name: biocard_imageLoad__3iZk4;
          animation-name: biocard_imageLoad__3iZk4;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

@-webkit-keyframes biocard_imageLoad__3iZk4 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes biocard_imageLoad__3iZk4 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 

.biocard_BioBlurb__1USfV {
  float: left;
  width: 50%;
  margin: 0;
}

.biocard_title__nhp4P {
  margin: 3rem 0 0 4rem;
  padding: 0 0 0 0;
  color: #333333;
  font-style: normal;
  font-weight: 900;
  text-align: left;
  font-size: 1.6rem;
  
}

.biocard_content__1OOu- {
  color: #333333;
  display: flex;
  margin: 0.5rem 0rem 3rem 4rem;
  white-space: wrap;
  letter-spacing: 0.0625em;
  text-justify: inter-word;
  /* text-align: justify !important; */
  text-align: left;
  font-size: 1.3rem;
  line-height: 2rem;
}

.biocard_hr__395ca {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  border: 0;
  height: 1px;
  background: #d0d0d0;
  margin: 0 2rem;
  color: gray; /*why doesn't this become gray?*/
}

.biocard_quoteBody__285n4 {
  display: flex;
  float: none;
  width: 100%;
  background-color: #f8f5f1;
}

.biocard_quote__3bjCG {
  float: none;
  padding: 1rem 1rem 0 1rem;
  margin: 2rem 2rem 0rem 2rem;
  font-size: 1.46rem;
  text-align: center;
  font-weight: 400;
  font-family: "Lato", Helvetica, sans-serif;
  font-style: italic;
  color: #333333;
}

.biocard_quoted__Zq9uc {
  float: none;
  padding: 0.5em 1em 1em;
  margin-top: 0;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 400;
  color: #333333;
}

@media (max-width: 950px) {
  .biocard_BiocardWrapper__1igxS {
    background-color: #ffffff;
    border-radius: 0.1rem;
    width: 100%;
  }

  .biocard_BioBody__1L2DA {
    margin: 0 auto;
    display: flex;
    width: 95%;
    flex-direction: column-reverse;
    text-align: center;
  }

  .biocard_BioWrapperImage__3asFQ {
    margin: 0 auto;
    width: 80%;
    padding-top: 1em;
  }

  .biocard_BioCardImage__1Lr8g {
    margin: 0 auto;
    text-align: center;
    padding: 1em 0 2em;
    width: 75%;
    border-radius: 0.25rem;
  }

  .biocard_BioBlurb__1USfV {
    margin: 0 auto;
    width: 85%;
    padding: 0em 1em 1em;
  }

  .biocard_title__nhp4P {
    margin: 0 auto;
    color: #333333;
    font-style: normal;
    font-weight: 900;
    text-align: center !important;
    font-size: 1.4rem;
  }

  .biocard_content__1OOu- {
    color: #333333;
    width: 100%;
    margin: 0 auto;
    padding-top: 0.8em;
    padding-bottom: 1.5rem;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: justify !important;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .biocard_hr__395ca {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border: 0;
    height: 1px;
    background: #d0d0d0;
    margin: 0 2rem;
    color: gray; /*why doesn't this become gray?*/
  }

  .biocard_quoteBody__285n4 {
    display: flex;
    font-family: "Lato", Helvetica, sans-serif;
    float: none;
    width: 100%;
    background-color: #f8f5f1;
  }

  .biocard_quote__3bjCG {
    float: none;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 400;
    font-style: italic;
  }

  .biocard_quoted__Zq9uc {
    float: none;
    padding-bottom: 2rem;
    margin-top: 0;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 400;
  }
}

@media (max-width: 450px) {
  .biocard_BiocardWrapper__1igxS {
    background-color: #ffffff;
    border-radius: 0.1rem;
    width: 100%;
  }

  .biocard_BioBody__1L2DA {
    margin: 0 auto;
    display: flex;
    width: 95%;
    flex-direction: column-reverse;
    text-align: center;
  }

  .biocard_BioWrapperImage__3asFQ {
    margin: 0 auto;
    width: 80%;
    padding-top: 1em;
  }

  .biocard_BioCardImage__1Lr8g {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    border-radius: 0.25rem;
  }

  .biocard_BioBlurb__1USfV {
    margin: 0 auto;
    width: 85%;
  }

  .biocard_title__nhp4P {
    margin: 0 auto;
    color: #333333;
    font-style: normal;
    font-weight: 900;
    text-align: center !important;
    font-size: 1.25rem;
  }

  .biocard_content__1OOu- {
    color: #333333;
    width: 100%;
    margin: 0 auto;
    padding-top: 0.5em;
    white-space: wrap;
    letter-spacing: 0.0325em;
    text-justify: inter-word;
    text-align: justify !important;
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .biocard_hr__395ca {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
    border: 0;
    height: 1px;
    background: #d0d0d0;
    margin: 0 2rem;
    color: gray; /*why doesn't this become gray?*/
  }

  .biocard_quote__3bjCG {
    float: none;
    padding: 0 1rem 0 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    font-style: italic;
  }

  .biocard_quoted__Zq9uc {
    float: none;
    padding-bottom: 2rem;
    margin-top: 0;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
  }
}
.featured-event_eventsLinks__1H1M4 {
    text-decoration: none;
    color: #198580;
    text-decoration: underline;
  }

.featured-event_eventsLinks__1H1M4:hover {    
    color: #00C6BF;
  }

.featured-event_FeaturedWrapper__1ZsUR {
    background-color: rgba(248, 245, 241, 0.8);
    border-radius: 0.1rem;
}

.featured-event_FeaturedBody__2d94P {
    display: flex;
    padding: 0 0 1.5rem 0;
    flex-direction: column;
    font-family: "Lato", Helvetica, sans-serif;
}

.featured-event_title__1eXtx {
    margin: 3rem 0 2rem 0rem;
    padding: 0 1rem 0 1rem;
    color: #333333;
    font-style: normal;
    font-weight: 900;
    text-align: center;
    font-size: 2rem;    
}

.featured-event_title2__p69NX {
    margin: 0rem 0 2rem 0rem;
    padding: 0 1rem 0 1rem;
    color: #333333;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 1.85rem;      
}

.featured-event_spanFeatured__KwkbU {
    font-style: italic;
    font-weight: 700;
}

.featured-event_eventEmphasis__1sfV_ {
    text-decoration: underline;
}

.featured-event_content__693uS {
    color: #4a4857;
    margin: 0.5rem 0rem 1rem 0rem;
    padding: 0 8rem 0 8rem;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center;
    font-size: 1.3rem;
    line-height: 2rem;
}

.featured-event_seeEvents__2vAi6 {
    color: #1C3158;
    margin: 2rem 0 2rem 0; 
    white-space: wrap;
    text-decoration: none;
    font-weight: 700;
    margin: 0.5rem 0rem 0rem 0rem;
    padding: 0rem 0 0.5rem 0;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center !important;
    font-size: 1.3rem;
    line-height: 2rem;    
}

.featured-event_seeEvents__2vAi6:hover {
    color: #6D0000;    
}

.featured-event_seeWorks__2Bvmb {
    color: #1C3158;
    white-space: wrap;
    text-decoration: none;
    font-weight: 700;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 0 0.5rem 0;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center !important;
    font-size: 1.7rem;
    line-height: 2rem;    
}

.featured-event_seeWorks__2Bvmb:hover {
    color: #6D0000;    
}

.featured-event_coverImg__Yaoma {
    border-radius: 0.25rem;
    width: 50%;
    -webkit-animation-name: featured-event_imageLoad__1-rOB;
            animation-name: featured-event_imageLoad__1-rOB;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

.featured-event_coverImg2__2dHsi {
    margin-bottom: .5rem;
    border-radius: 0.25rem;
    width: 50%;
    -webkit-animation-name: featured-event_imageLoad__1-rOB;
            animation-name: featured-event_imageLoad__1-rOB;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}


@-webkit-keyframes featured-event_featuredTitle__2thh0 {
    0% {
        color: #333333;
        opacity: 0;
    }

    100% {
        color: #007488;
        opacity: 1;
    }
}


@keyframes featured-event_featuredTitle__2thh0 {
    0% {
        color: #333333;
        opacity: 0;
    }

    100% {
        color: #007488;
        opacity: 1;
    }
}

@-webkit-keyframes featured-event_featuredContent__JLi_p {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes featured-event_featuredContent__JLi_p {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes featured-event_imageLoad__1-rOB {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes featured-event_imageLoad__1-rOB {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 950px) {
    .featured-event_FeaturedWrapper__1ZsUR {
        background-color: rgba(248, 245, 241, 0.8);
        border-radius: 0.1rem;
        width: 100%;
    }
    .featured-event_coverImg__Yaoma {
        margin: 0 auto;
        text-align: center;
        width: 75%;
    }
    .featured-event_title__1eXtx {
        width: 85%;
        padding: 2rem 0 1.5rem 0;
        margin: 0 auto;
        font-size: 1.4rem;
    }
    .featured-event_title2__p69NX {
        width: 85%;
        padding: 0rem 0 1.5rem 0;
        margin: 0 auto;
        font-size: 1.25rem;    
    }

    .featured-event_content__693uS {
        width: 85%;
        margin: 0 auto;
        padding: 0.5rem 0 1.5rem 0;       
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .featured-event_seeWorks__2Bvmb, .featured-event_seeEvents__2vAi6 {
        font-size: 1.15rem;
        margin: 0 auto;
        padding: 0 .5rem 0 .5rem;
        text-justify: inter-word;
    }
}

@media (max-width: 475px) {
    .featured-event_title__1eXtx {        
        font-size: 1.2rem;
    }
    .featured-event_title2__p69NX {        
        font-size: 1.1rem;
    }
    .featured-event_content__693uS {     
        margin: 0 auto;        
        font-size: 1rem;        
    }
    .featured-event_seeWorks__2Bvmb, .featured-event_seeEvents__2vAi6 {  
        padding: 0 .5rem 0 .5rem;      
        font-size: 1rem;
    }
}
.article_eventsLinks__3AuKX {
    text-decoration: none;
    color: #198580;
    text-decoration: underline;
  }

.article_eventsLinks__3AuKX:hover {    
    color: #00C6BF;
  }

.article_ArticleWrapper__3hlF1 {
    background-color: rgba(248, 245, 241, 0.8);
    border-radius: 0.1rem;
}

.article_ArticleBody__2E_N3 {
    display: flex;
    padding: 0 0 1.5rem 0;
    flex-direction: column;
    font-family: "Lato", Helvetica, sans-serif;
}

.article_title__2mfqT {
    margin: 3rem 0 2rem 0rem;
    padding: 0 2.5rem 0 2.5rem;
    color: #333333;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 2rem;    
}

.article_title2__1dkLl {
    margin: 0 0 .75rem 0rem;
    padding: 0 0 .75rem 0;
    white-space: wrap;
    color: #445580;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;    
}

.article_spanArticle__3Rcx2 {
    font-style: italic;
    font-weight: 700;
}

.article_eventEmphasis__15PJm {
    text-decoration: underline;
}

.article_content__2IMih {
    color: #4a4857;
    margin: 0.5rem 0rem 1rem 0rem;
    padding: 0 8rem 0 8rem;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center;
    font-size: 1.3rem;
    line-height: 2rem;
}

.article_seeEvents__2RgEv {
    color: #1C3158;
    margin: 2rem 0 2rem 0; 
    white-space: wrap;
    text-decoration: none;
    font-weight: 700;
    margin: 0.5rem 0rem 0rem 0rem;
    padding: 0rem 0 0.5rem 0;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center !important;
    font-size: 1.3rem;
    line-height: 2rem;    
}

.article_seeEvents__2RgEv:hover {
    color: #6D0000;    
}

.article_seeWorks__26l9K {
    color: #1C3158;
    white-space: wrap;
    text-decoration: none;
    font-weight: 700;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 0 0.5rem 0;
    white-space: wrap;
    letter-spacing: 0.0625em;
    text-justify: inter-word;
    text-align: center !important;
    font-size: 1.7rem;
    line-height: 2rem;    
}

.article_seeWorks__26l9K:hover {
    color: #6D0000;    
}

.article_articleImg__3BOvK {
    border-radius: 0.25rem;
    width: 40%;
    -webkit-animation-name: article_imageLoad__3EPpp;
            animation-name: article_imageLoad__3EPpp;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

.article_articleImg2__3JpjW {
    margin-bottom: .5rem;
    border-radius: 0.25rem;
    width: 50%;
    -webkit-animation-name: article_imageLoad__3EPpp;
            animation-name: article_imageLoad__3EPpp;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}


@-webkit-keyframes article_ArticleTitle__iOoCz {
    0% {
        color: #333333;
        opacity: 0;
    }

    100% {
        color: #007488;
        opacity: 1;
    }
}


@keyframes article_ArticleTitle__iOoCz {
    0% {
        color: #333333;
        opacity: 0;
    }

    100% {
        color: #007488;
        opacity: 1;
    }
}

@-webkit-keyframes article_ArticleContent__2ltJ4 {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes article_ArticleContent__2ltJ4 {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes article_imageLoad__3EPpp {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes article_imageLoad__3EPpp {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 950px) {
    .article_ArticleWrapper__3hlF1 {
        background-color: rgba(248, 245, 241, 0.8);
        border-radius: 0.1rem;
        width: 100%;
    }
    .article_articleImg__3BOvK {
        margin: 0 auto;
        text-align: center;
        width: 50%;
    }
    .article_title__2mfqT {
        width: 85%;
        padding: 2rem 0 1.5rem 0;
        margin: 0 auto;
        font-size: 1.4rem;
    }
    .article_title2__1dkLl {
        width: 85%;
        padding: 0rem 0 1.5rem 0;
        margin: 0 auto;
        font-size: 1.25rem;    
    }

    .article_content__2IMih {
        width: 85%;
        margin: 0 auto;
        padding: 0.5rem 0 1.5rem 0;       
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .article_seeWorks__26l9K, .article_seeEvents__2RgEv {
        font-size: 1.15rem;
        margin: 0 auto;
        padding: 0 .5rem 0 .5rem;
        text-justify: inter-word;
    }
}

@media (max-width: 475px) {
    .article_title__2mfqT {        
        font-size: 1.2rem;
    }
    .article_title2__1dkLl {        
        font-size: 1.1rem;
    }
    .article_content__2IMih {     
        margin: 0 auto;        
        font-size: 1rem;        
    }
    .article_seeWorks__26l9K, .article_seeEvents__2RgEv {  
        padding: 0 .5rem 0 .5rem;      
        font-size: 1rem;
    }
}
