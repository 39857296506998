@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");


.preloader {
  background-color: #ffffff;
  background-image: url("/assets/preloader.gif");
  height: 20rem;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.wrapper {
  height: 30%;
  width: 60%;
  max-width: 800px;
  margin: 2rem auto;
  padding-top: 2rem;
  background-color: rgba(248, 245, 241, 0.8) !important;
 
}
.header {
  font-size: 1.5rem;
  color: #333333;
  font-weight: 900;
  padding: 0 .5em;
  font-family: "Lato", Helvetica, sans-serif;
}

.content {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 4em;
  font-family: "Lato", Helvetica, sans-serif;
}


@media (max-width: 850px) {

  .header, .content {
    font-size: 1.5rem;
    font-weight: 900;
    font-family: "Lato", Helvetica, sans-serif;
  }

  }


  @media (max-width: 550px) {

    .header, .content {
      font-size: 1.2rem;
      font-weight: 900;
      font-family: "Lato", Helvetica, sans-serif;

    }
  
    }

    
  @media (max-width: 450px) {

    .header {
      font-size: 1.2rem;
      font-weight: 900;
      font-family: "Lato", Helvetica, sans-serif;
    }
  
    }