@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

.wrapper {
  width: 65%;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 5rem;
  padding: 1rem 0 1rem 0;
  max-width: 960px;
  background-color: rgba(248, 245, 241, 0.8);
 
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  border: none;
}

.header {
  padding: 1em 0 1em 0;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: #333333;
  
}

.formRow {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
}

.input {
  width: 65%;
  max-width: 60rem;
  height: 2rem;
  padding: 0.25rem 0.5rem 0.5rem .5rem;
  border: 1rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: none;
  font-family: "Lato", sans-serif;
  font-size: 1.15rem;
  
}

.textarea {
  width: 65%;
  max-width: 60rem;
  height: 10rem;
  padding: 0.5rem;
  border: 1rem;
  border-radius: 0.25rem;
  border-style: none;
  font-size: 1.15rem;
  
}

.formMessage {
  padding-bottom: 1em;
}
.button {
  margin: 0 auto;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Lato", sans-serif;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Lato", sans-serif;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Lato", sans-serif;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Lato", sans-serif;
}


 @media (max-width: 450px) {

  .header {
    padding: 1em .5em 1em .5em;
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    
  }

.button {
  margin: 0 auto;
  font-size: .5rem !important;
  width: 73% !important;
}

}