@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");

.buyButton {
  background-color: #580c31;
  color: white;
  padding: 0.5rem 0.9rem;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  outline: none;
  border-style: none;
}

.buyButton:hover {
  background-color:#79013d;
}

@media (max-width: 450px) {
  .buyButton {
    width: 95%;
    font-size: .9rem;
  }
}
